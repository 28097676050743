import React from "react"
import Layout from "../components/layout"
import Skills from "../components/skills/skills"
import { Link } from "gatsby"

const SkillsPage = () => (
  <Layout>
    <h1>Skill</h1>
    <p>
      Ho iniziato a sviluppare siti web alla fine degli anni '90, da allora ho
      sviluppato diverse competenze, alcune sicuramente obsolete :P
    </p>
    <p>
      In questa pagina ho provato ad elencare le skill più significative, sia
      tecniche che non.
    </p>
    <Skills/>
    <div className="columns">
      <div className="b-box">
        <h3>Analysis e consultancy</h3>
        <p>Analisi e ottimizzazione delle prestazioni del sito internet; 
          valutazione dell'usabilità e A/B testing; 
          progettazione di architetture CSS, design system e coding guidelines;
          </p>
      </div>
      <div className="b-box">
        <h3>Training</h3>
        <p>Negli anni ho avuto la possibilità di organizzare e tenere diversi corsi di formazione: 
          sviluppo siti web, gestione di siti in TYPO3, sviluppo temi per TYPO3 e Magento.<br/>
          Ho partecipato come <Link to="speaker">speaker</Link> a diverse conferenze,
        </p>
      </div>
    </div>
  </Layout>
)
export default SkillsPage
