import React from "react"
import styles from "./skills.module.scss"

export default () => (
    <div className={styles.stripesContainer}>
      <div className={styles.stripe}>
        <h4 className={styles.stripeTitle}>
          <span>HTML</span>
        </h4>
        <div className={styles.stripeContent}>
          <ul>
            <li>RWD</li>
            <li>Schema.org</li>
            <li>ARIA</li>
            <li>SEO</li>
          </ul>
        </div>
      </div>

      <div className={styles.stripe}>
        <h4 className={styles.stripeTitle}>
          <span>CSS</span>
        </h4>
        <div className={styles.stripeContent}>
          <ul>
            <li>CSS3</li>
            <li>SASS</li>
            <li>CSS Animations</li>
            <li>CSS Architecture</li>
            <li>Bootstrap</li>
          </ul>
        </div>
      </div>

      <div className={styles.stripe}>
        <h4 className={styles.stripeTitle}>
          <span>JS</span>
        </h4>
        <div className={styles.stripeContent}>
          <ul>
            <li>jQuery</li>
            <li>React</li>
            <li>Vue.js</li>
            <li>ES6+</li>
            <li>Service Workers</li>
          </ul>
        </div>
      </div>

      <div className={styles.stripe}>
        <h4 className={styles.stripeTitle}>
          <span>CMS</span>
        </h4>
        <div className={styles.stripeContent}>
          <ul>
            <li>Magento</li>
            <li>TYPO3</li>
            <li>Wordpress</li>
          </ul>
        </div>
      </div>

      <div className={styles.stripe}>
        <h4 className={styles.stripeTitle}>
          <span>UX Design</span>
        </h4>
        <div className={styles.stripeContent}>
          <ul>
            <li>Figma</li>
            <li>Adobe XD</li>
          </ul>
        </div>
      </div>

      <div className={styles.stripe}>
        <h4 className={styles.stripeTitle}>
          <span>Altro</span>
        </h4>
        <div className={styles.stripeContent}>
          <ul>
            <li>GIT</li>
            <li>Linux</li>
            <li>Gulp,Webpack</li>
            <li>Npm</li>
            <li>GatsbyJS</li>
          </ul>
        </div>
      </div>

    </div>
)